import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	Typography,
} from "@mui/material";

import { RootState } from "../../../store";
import {
	CompetencyReview,
	Question,
	QuestionGroup,
	QuestionListItem,
	ScaleButton,
	Survey,
	SurveyAnswer,
	SurveyDocumentStateModel,
	SurveySection,
} from "../../../@types/surveys.d";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { current } from "@reduxjs/toolkit";
import { getSelectedAnswer } from "../store/SurveyState";

export const SurveyCompetenciesReviewComponent: React.FC = () => {
	const { showSectionSubheading } = useSelector(
		(state: RootState) => state.navigation
	);
	const { activeAnswers, activeSurvey, currentSection } = useSelector(
		(state: RootState) => state.user
	);

	const buildQuestionList = (
		surveySections: Array<SurveySection>,
		surveyAnswers: Array<SurveyAnswer>
	) => {
		let questionList: Array<QuestionListItem> = [];

		surveySections.forEach((surveySection: SurveySection) => {
			if (!surveySection.questionGroups) return;
			surveySection?.questionGroups?.forEach((questionGroup: QuestionGroup) => {
				if (!questionGroup.questions) return;

				questionGroup.questions.forEach((question: Question) => {
					const answers: Array<SurveyAnswer> = surveyAnswers
						.filter(
							(surveyAnswer: SurveyAnswer) =>
								surveyAnswer.formItemId === question.formItemId
						)
						.sort(
							(a: SurveyAnswer, b: SurveyAnswer) =>
								b.answeredTimeMs - a.answeredTimeMs
						); // sort in descending order

					questionList.push({
						sectionId: surveySection.sectionId,
						formItemId: question.formItemId,
						formItemSequenceNumber: question.formItemSequenceNumber,
						answer: (answers.length > 0 ? answers[0].answer : null) as string,
						question: question,
					});

					if (question.dependentOption) {
						const answers: Array<SurveyAnswer> = surveyAnswers
							.filter(
								(surveyAnswer: SurveyAnswer) =>
									surveyAnswer.formItemId ===
									question.dependentOption.formItemId
							)
							.sort(
								(a: SurveyAnswer, b: SurveyAnswer) =>
									b.answeredTimeMs - a.answeredTimeMs
							); // sort in descending order

						questionList.push({
							sectionId: surveySection.sectionId,
							formItemId: question.dependentOption.formItemId ?? 0,
							formItemSequenceNumber:
								question.dependentOption.formItemSequenceNumber ?? 0,
							answer: (answers.length > 0 ? answers[0].answer : null) as string,
							question: question,
						});
					}

					if (
						question.options &&
						question.options.some((option) => !!option.formItemId)
					) {
						question.options.forEach((option) => {
							const answers: Array<SurveyAnswer> = surveyAnswers
								.filter(
									(surveyAnswer: SurveyAnswer) =>
										surveyAnswer.formItemId === option.formItemId
								)
								.sort(
									(a: SurveyAnswer, b: SurveyAnswer) =>
										b.answeredTimeMs - a.answeredTimeMs
								); // sort in descending order

							questionList.push({
								sectionId: surveySection.sectionId,
								formItemId: option.formItemId ?? 0,
								formItemSequenceNumber: option.formItemSequenceNumber ?? 0,
								answer: (answers.length > 0
									? answers[0].answer
									: null) as string,
							});

							if (option.dependentFormItemSequenceNumber) {
								const answers: Array<SurveyAnswer> = surveyAnswers
									.filter(
										(surveyAnswer: SurveyAnswer) =>
											surveyAnswer.formItemId ===
											option.dependentOption.formItemId
									)
									.sort(
										(a: SurveyAnswer, b: SurveyAnswer) =>
											b.answeredTimeMs - a.answeredTimeMs
									); // sort in descending order

								questionList.push({
									sectionId: surveySection.sectionId,
									formItemId: option.dependentOption.formItemId ?? 0,
									formItemSequenceNumber:
										option.dependentOption.formItemSequenceNumber ?? 0,
									answer: (answers.length > 0
										? answers[0].answer
										: null) as string,
								});
							}
						});
					}
				});
			});
		});

		return questionList;
	};

	const getCompetenciesReviews = () => {
		let questionList = buildQuestionList(
			activeSurvey?.surveyDocument.survey.surveySections,
			activeSurvey.surveyAnswers
		);

		let survey = activeSurvey.surveyDocument.survey;

		// get the competency section
		const competencySection: SurveySection | undefined =
			survey.surveySections.find(
				(surveySection: SurveySection) =>
					surveySection.component === "SurveyCompetenciesQuestionsComponent"
			);

		// get the selected competencies
		const selectedQuestions: Array<Question> | undefined =
			competencySection?.questionGroups[0].questions.filter(
				(question: Question) =>
					questionList?.some((questionListItem: QuestionListItem) => {
						let answer = getSelectedAnswer(
							questionListItem.formItemId,
							activeAnswers
						);
						return (
							questionListItem.formItemId === question.formItemId &&
							answer === "1"
						);
					})
			);

		// map all the question groups from the survey to make it easier to find the
		// correct question group
		let questionGroups: Array<QuestionGroup> = [];
		survey.surveySections
			.filter(
				(surveySection: SurveySection) => surveySection.questionGroups !== null
			)
			.map((surveySection: SurveySection) => {
				surveySection.questionGroups.map((questionGroup: QuestionGroup) => {
					questionGroups.push(questionGroup);
				});
			});

		// out of the selected competency questions
		return selectedQuestions?.map((competencyQuestion: Question) => {
			// find the question group for this selected competency
			const questionGroup: QuestionGroup | undefined = questionGroups.find(
				(questionGroup: QuestionGroup) =>
					questionGroup.questions.some(
						(question: Question) =>
							question.parentFormItemId === competencyQuestion.parentFormItemId
					)
			);

			// return the new competency review qroups with question grouped properly
			return {
				name: competencyQuestion.text,
				groups: questionGroup?.surveyScale.buttons
					.filter(
						(button: ScaleButton) =>
							button.reviewable && button.reviewable === true
					)
					.map((button: ScaleButton) => {
						return {
							name: button.meaning,
							questions: questionGroup.questions.filter(
								(question: Question) => {
									let answer = getSelectedAnswer(
										question.formItemId,
										activeAnswers
									);

									return questionList?.find(
										(questionListItem: QuestionListItem) =>
											questionListItem.formItemId === question.formItemId &&
											answer === button.buttonValue
									);
								}
							),
						};
					}),
			};
		});
	};

	const [compentencyReviews, setCompentencyReviews] = useState<
		any | undefined
	>();

	const displayError = (n: number) => {
		const index = currentSection.extensions.findIndex((x) => x.name === (n === 1 ? 'noReviews' : 'noQuestions'));
		return currentSection.extensions[index].value ?? "";
	}

	useEffect(() => {
		if (activeSurvey) {
			setCompentencyReviews(getCompetenciesReviews());
		}
	}, [activeSurvey]);

	useEffect(() => {
		if (compentencyReviews) {
			console.log("compentencyReviews", compentencyReviews);
		}
	}, [compentencyReviews]);

	return (
		<Box sx={{ flexGrow: 1, ml: 3, mr: 3 }} className="question-card">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{currentSection.heading && (
						<Typography
							gutterBottom
							variant="subtitle2"
							fontWeight={900}
							component="div"
							className="p-3 question-title"
							sx={{
								px: 7,
								pt: 5,
								pb: currentSection?.subheadings !== null ? 3 : 0,
							}}
						>
							{currentSection.heading}
						</Typography>
					)}
				</Grid>
				<Grid item xs={12}>
					{showSectionSubheading && currentSection?.subheadings && (
						<div className="section-subheading">
							{currentSection.subheadings?.map((item, index) => {
								return (
									<Typography
										key={index}
										gutterBottom
										variant="h6"
										component="div"
										className="p-3 text-left question-subtitle"
										sx={{ px: 2 }}
									>
										{item}
									</Typography>
								);
							})}
						</div>
					)}
				</Grid>
				<Grid item xs={12}>
					{currentSection.information.map((item, index) => {
						return (
							<Box sx={{ flexGrow: 1, mt: 5, ml: 7 }} key={index}>
								<Typography
									gutterBottom
									variant="h6"
									component="div"
									className="p-3 text-left question-title"
								>
									<ReactMarkdown rehypePlugins={[rehypeRaw]}>
										{item}
									</ReactMarkdown>
								</Typography>
							</Box>
						);
					})}
				</Grid>
				<Grid item xs={12}>
					{(compentencyReviews?.length > 0) ? (
						<Fragment>
							{compentencyReviews.map((review: any, index: number) => {
								return (
									<Card
										key={index}
										sx={{
											mx: 3
										}}
									>
										<CardHeader
											title={
												<Typography variant="h4" fontWeight={700}>
													{review.name}
												</Typography>
											}
										/>
										<CardContent>
											{review?.groups?.map((group, index) => {
												return (
													<Fragment key={index}>
															<Card
																sx={{
																	mx: 3
																}}
															>
																<CardHeader
																	title={
																		<Typography
																			variant="h6"
																			sx={{
																				color: "#ffffff",
																				bgcolor: "#003da7",
																				p: 3,
																				borderTopLeftRadius: 5,
																				borderTopRightRadius: 5,
																			}}
																		>
																			{group.name}
																		</Typography>
																	}
																/>
																<CardContent>
																{group.questions?.length > 0 ? (
																	<ul>
																		{group.questions.map(
																			(question: any, index: number) => {
																				return (
																					<li key={index}>{question.text}</li>
																				);
																			}
																		)}
																	</ul>
																	) : (
																		<Fragment>
																			{<Box sx={{ flexGrow: 1, ml: 7 }}>
																					<Typography
																						gutterBottom
																						variant="body1"
																						component="div"
																						fontSize="14px"
																						className="p-3 text-left question-title"
																					>
																						{displayError(2)}
																					</Typography>
																				</Box>
																			}
																		</Fragment>
																	)}
																</CardContent>
															</Card>
													</Fragment>
												);
											})}
										</CardContent>
									</Card>
								);
							})}
						</Fragment>
					) : (
						<Fragment>
							<Box sx={{ flexGrow: 1, mt: 5, ml: 7 }}>
								<Typography
									gutterBottom
									variant="h6"
									component="div"
									className="p-3 text-left question-title"
									color={"red"}
								>
										{displayError(1)}
								</Typography>
							</Box>
						</Fragment>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};
