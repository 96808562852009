import { useEffect } from "react";
import { CompatRouter, Route, Routes } from "react-router-dom-v5-compat";

import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material/styles";

// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import useTheme from "./hooks/useTheme";

import createTheme from "./theme";

import {
	HomePage,
	NoWorkPage,
	Logout,
	WhoAmI,
	AboutMeSurvey,
	WorkPlaceBigFiveSurvey,
	AssessmentSurvey,
	AssessmentRaters,
} from "./pages";
import { Error } from "./pages/Error";
import ProfilePage from "./pages/ProfilePage";

import { ProtectedRoute } from "./components/shared/protected-route";

import { useEnv } from "./context/env.context";

import { TranslationProvider } from "./context/TranslationProvider";

import "./App.scss";

// Firebase is currently only in PROD
const firebaseConfig = {
	apiKey: "AIzaSyDITTQONqF3pNxGbJDjFoSR_rl2vre_hMA",
	authDomain: "about-me-dcb01.firebaseapp.com",
	projectId: "about-me-dcb01",
	storageBucket: "about-me-dcb01.appspot.com",
	messagingSenderId: "296723557075",
	appId: "1:296723557075:web:c0a0017013d06191298226",
	measurementId: "G-VS9DYP95LZ",
};

export const App: React.FC = () => {
	const { error, isAuthenticated, getIdTokenClaims } = useAuth0();

	const { theme } = useTheme();
	const { domain } = useEnv();

	useEffect(() => {
		if (isAuthenticated) {
			getIdTokenClaims().then((claims) => {
				localStorage.setItem("token", claims?.__raw as string);
			});
		}
		// eslint-disable-next-line
	}, [isAuthenticated]);

	let firebaseApp: FirebaseApp;
	let analytics: Analytics;

	// PROD only
	// if (domain === "auth.ccl.org") { //temporary disable for testing
		// Initialize Firebase
		firebaseApp = initializeApp(firebaseConfig);
		analytics = getAnalytics(firebaseApp);
	// }

	if (error !== undefined) {
		console.log(error.message);
		return (
			<div>
				<Error />
			</div>
		);
	}

	return (
		<div className="App" data-testid="main-app-div">
			<ThemeProvider theme={createTheme(theme)}>
				<TranslationProvider>
					<CompatRouter>
						<Routes>
							<Route>
								{/* <VerifyAccess /> */}
								<Route path="/logout" element={<Logout />} />
								<Route
									path="/who-am-i"
									element={<ProtectedRoute component={WhoAmI} />}
								/>
								<Route
									path="/aboutme"
									element={<ProtectedRoute component={AboutMeSurvey} />}
								/>
								<Route
									path="/raters"
									element={<ProtectedRoute component={AssessmentRaters} />}
								/>
								<Route
									path="/survey"
									element={<ProtectedRoute component={AssessmentSurvey} />}
								/>
								<Route
									path="/wb5p"
									element={
										<ProtectedRoute component={WorkPlaceBigFiveSurvey} />
									}
								/>
								<Route
									path="/profile"
									element={<ProtectedRoute component={ProfilePage} />}
								/>
								<Route
									path="/no-work"
									element={<ProtectedRoute component={NoWorkPage} />}
								/>
								<Route
									path="/"
									element={<ProtectedRoute component={HomePage} />}
								/>
							</Route>
						</Routes>
					</CompatRouter>
				</TranslationProvider>
			</ThemeProvider>
		</div>
	);
};
