import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";
import {
	Box,
	Card,
	CardContent,
	Grid,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";

import { RootState } from "../../store";

import forOthersImage from "../../assets/images/about_others.png";
import {
	fetchSurveyUsage,
	setLoading,
	setProgramRatersStatuses,
} from "../../store/User/UserSlice";
import { getAllRatersStatus } from "../../services/apiService";
import { SurveyStatus } from "../../@types/raters.d";

import { FormattedPhrase } from "../../context/FormattedPhrase";
import { PastSurveys } from "./PastSurveys";
import { CurrentSurveys } from "./CurrentSurveys";

export const AssessmentsForOthers: React.FC = () => {
	const dispatch = useDispatch();

	const { user } = useAuth0();
	const theme = useTheme();

	const { profileResponse, programRaterStatuses, loading, surveyUsageData } = useSelector(
		(state: RootState) => state.user
	);
	const { returnUrl } = useSelector((state: RootState) => state.navigation);

	const [completedSurveys, setCompletedSurveys ] = useState<Array<SurveyStatus>>();
	const [currentSurveys, setCurrentSurveys ] = useState<Array<SurveyStatus>>();

	const asyncLoadingTasks = async () => {
		if (user && profileResponse) {
			dispatch(setLoading(true));
			try {
				let acctId = user["http://schemas.ccl.org/accounts/claims/account/id"];

				let resp = await getAllRatersStatus(
					acctId,
					profileResponse?.individual?.preferredApplicationLanguageId ?? 1
				);
				console.log("resp", resp);
				dispatch(setProgramRatersStatuses(resp));
				dispatch(fetchSurveyUsage());
			} finally {
				dispatch(setLoading(false));
			}
		}
	};

	useEffect(() => {
		if (returnUrl === "") {
			localStorage.setItem("redirectUser", "");
			localStorage.setItem("redirectOnLogOut", "");
			localStorage.setItem("returnToAp1", "");
		}
		let newStatuses: Array<SurveyStatus> = [];
		if (programRaterStatuses) {
			programRaterStatuses?.surveyStatuses?.forEach((element) => {
				if (surveyUsageData) {
					surveyUsageData.map((x) => {
					if (element.instrument.instrumentId === x.instrumentKey) {
						newStatuses.push({
							...element,
							instrument: {
								isSurveyAvailable: x.active,
								surveyPlatform: x.surveyPlatform,
								instrumentId: element.instrument.instrumentId,
								name: element.instrument.name
							}
						});
					}
					})
				}
			});
			if (newStatuses.length > 0) {
				let completedRaterStatus = newStatuses?.filter(x => {
					return x.surveyStatusId > 2;
				});
				setCompletedSurveys(completedRaterStatus);

				let currentRaterStatus = newStatuses?.filter(x => {
					return x.surveyStatusId < 3;
				});
				setCurrentSurveys(currentRaterStatus);
			}
		}
	},[programRaterStatuses, surveyUsageData])

	useEffect(() => {
		localStorage.setItem("redirectUser", "");
		asyncLoadingTasks();
		window.scrollTo(0, 0);
	}, [user, profileResponse]);

	return (
		<Grid container spacing={2}>
			{!loading &&
			<>
			<Grid item xs={12} md={4}>
				<Card
					sx={{
						m: 3,
						boxShadow:
							"0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
					}}
				>
					<CardContent>
						<Stack>
							<img
								src={forOthersImage}
								alt="About Me"
								style={{ width: "100%" }}
							/>
							<Typography variant="title" sx={{ my: 3 }}>
								<FormattedPhrase id="tabbedLanding.tab.surveysForOthers" />
							</Typography>
							<Typography variant="body1">
								<FormattedPhrase id="raterLanding.header.instructions" />
							</Typography>
						</Stack>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} md={8}>
				<Fragment>
					{currentSurveys && currentSurveys?.length > 0 &&
						<Card sx={{ ml: 3, backgroundColor: "#F1F1F2" }}>
							<CurrentSurveys programRaterStatuses={currentSurveys} />
						</Card>
					}
					{completedSurveys && completedSurveys?.length > 0 &&
						<Card sx={{ ml: 3, backgroundColor: "#F1F1F2" }}>
							<PastSurveys programStatuses={completedSurveys} />
						</Card>
					}
				</Fragment>
			</Grid>
			</>
		}
		</Grid>
	);
};
