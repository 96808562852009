import { Fragment, useEffect, useRef, useState } from "react";
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	FormControl,
	MenuItem,
	Select,
	Container,
	Grid,
	InputLabel,
	Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { RootState } from "../../store";
import {
	fetchSurvey,
	setActiveSurvey,
	setCurrentSection,
	setLoading,
	setLocalSurveyLanguage,
	setSelectedSurveyLanguage,
} from "../../store/User/UserSlice";
import {
	setAllowNext,
	setAllowPrevious,
} from "../../store/Navigation/NavSlice";
import { useAuth0 } from "@auth0/auth0-react";
import {
	changeSurveyLanguage,
	getApplicationLanguages,
	getIndex,
} from "../../services/apiService";
import { Language } from "../../@types/language.d";
import "./All.css";
import { useTranslationContext } from "../../context/TranslationContext";
import { AlertModal } from "../shared/alertModal";

const LanguageDropdown = (props) => {
	const dispatch = useDispatch();
	const theme = useTheme();

	const { user } = useAuth0();

	const {
		activeSurvey,
		currentSection,
		instrumentId,
		participantId,
		profileResponse,
		selectedSurveyType,
		selectedLanguage,
		localSurveyLanguage,
	} = useSelector((state: RootState) => state.user);

	const [languages, setLanguages] = useState<Array<any>>([]);
	const [language, setLanguage] = useState<Language>({} as Language);
	const [newLanguage, setNewLanguage] = useState<string>("1");
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const { getFormattedPhrase } = useTranslationContext();
	const [promptText, setPromptText] = useState<string>("");
	const [yesText, setYesText] = useState<string>("");
	const [noText, setNoText] = useState<string>("");
	const prevLanguage = useRef(newLanguage);
	const [alertOpen, setAlertOpen] = useState(false);

	useEffect(() => {
		if (activeSurvey) {
			if (activeSurvey?.surveyDocument?.languages) {
				let surveyLanguages = activeSurvey.surveyDocument.languages;

				// TEMPORARILY ADDING FOR ISSUES CASED BY 74847 //
				// if (activeSurvey.surveyDocument.survey.instrumentId === 2946) {
				// 	surveyLanguages = surveyLanguages.filter((l: any) => l.languageId != 221);
				// }
				// REMOVE BLOCK WHEN ABOUT ME IS AVAILABLE IN ARABIC //

				if (selectedLanguage?.name === "English") {
					setLanguages(surveyLanguages);
				} else {
					getApplicationLanguages(selectedLanguage?.languageId)
						.then((res) => {
							if (res) {
								let surveyLanguages = res.filter((o1) =>
									surveyLanguages.some(
										(o2) => o1.languageId === o2.languageId
									)
								);
								res.map((x) => {
									surveyLanguages.map((y) => {
										if (y.languageId === x.languageId) {
											return (y.translatedLanguageName = x.name);
										}
									});
								});
								setLanguages(surveyLanguages);
							} else {
								setLanguages(surveyLanguages);
							}
						})
						.catch((e) => {
							console.log("error getting language list");
							setLanguages(surveyLanguages);
						});
				}
			}

			let survey = activeSurvey?.surveyDocument?.survey;

			let surveyLanguage = profileResponse?.applicationLanguages?.find(
				(l) => l.languageId === survey.languageId
			);
			setLanguage(surveyLanguage as Language);
			if (surveyLanguage)
				dispatch(setLocalSurveyLanguage(surveyLanguage?.cultureName));
			dispatch(setSelectedSurveyLanguage(surveyLanguage as Language));

			if (activeSurvey?.surveyDocument?.survey?.surveySections?.length > 0) {
				let index = 0;
				if (currentSection) {
					index = getIndex(
						currentSection,
						activeSurvey.surveyDocument.survey.surveySections,
						"sectionId"
					);
					if (index === -1) {
						index = 0;
					}
				}
				dispatch(
					setCurrentSection(
						activeSurvey.surveyDocument.survey.surveySections[index]
					)
				);
			}
		}
	}, [activeSurvey, language, localSurveyLanguage, selectedLanguage]);

	useEffect(() => {
		if (currentSection) {
			let extensions =
				currentSection?.extensions !== null
					? currentSection?.extensions
					: activeSurvey?.surveyDocument?.survey?.surveySections[0]?.extensions;

			let item = extensions.find((x) => x.name === "confirmationBody");
			setPromptText(item?.value ?? "");

			item = extensions.find((x) => x.name === "yesButton");
			setYesText(item?.value ?? "");

			item = extensions.find((x) => x.name === "noButton");
			setNoText(item?.value ?? "");
		}
	}, [currentSection]);

	useEffect(() => {},[newLanguage])

	const startChange = (e) => {
		if (`${e.target.value}` !== language?.languageId.toString()) {
			setNewLanguage(e.target.value);
			let newSurveyLanguage = profileResponse.applicationLanguages.find(
				(l) => l.languageId === e.target.value
			);
			dispatch(setSelectedSurveyLanguage(newSurveyLanguage as Language));
			setModalOpen(true);
		}
	};

	const handleChange = async (e) => {
		if (user && profileResponse) {
			dispatch(setLoading(true));
			setModalOpen(false);

			let acctId = user["http://schemas.ccl.org/accounts/claims/account/id"];
			if (prevLanguage.current !== newLanguage) {
				dispatch(setLoading(true));
				await changeSurveyLanguage(
					acctId,
					instrumentId,
					participantId,
					activeSurvey.surveyDocument.survey.languageId,
					parseInt(newLanguage),
					selectedSurveyType
				).then((res) => {
					let changedLanguage = res?.surveyDocument?.survey?.languageId;
					console.log("changedLanguageId " + changedLanguage);
					dispatch(setActiveSurvey(res));
					dispatch(setLoading(false));
				}).catch((e) => {
					console.log(getFormattedPhrase("error.savingResponse"));
					setAlertOpen(true);
					dispatch(setLoading(false));
				});
				let data = {
					user: user,
					instrumentId: instrumentId,
					participantId: participantId,
					languageId: newLanguage,
					surveyTypeId: selectedSurveyType,
				};
				dispatch(fetchSurvey(data));
				dispatch(setLoading(true));
			}
			else {
				console.log(getFormattedPhrase("error.savingResponse"));
				setAlertOpen(true);
				dispatch(setLoading(false));
			}
		}
	};

	return (
		<Fragment>
			<AlertModal text={getFormattedPhrase("error.savingResponse")} onClose={() => setAlertOpen(false)} isOpen={alertOpen} />
			<Dialog open={modalOpen}>
				<DialogContent>
					<DialogContentText sx={{ color: "#3b3b3b" }}>
						{promptText}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleChange}
						size="small"
						variant="contained"
						className="btn-style"
					>
						{yesText}
					</Button>
					<Button
						onClick={() => setModalOpen(false)}
						variant="outlined"
						size="small"
						className="btn-style"
					>
						{noText}
					</Button>
				</DialogActions>
			</Dialog>

			{languages && languages?.length > 0 && (
				<Container sx={{ ml: -2 }}>
					{languages.length !== 1 && (
						<Grid container display={"flex"} flexDirection={"column"}>
							<Typography variant="subtitle2" fontWeight={700} ml={-1} mb={5}>
								{getFormattedPhrase("surveys.selectLanguage", null, language?.cultureName)}
							</Typography>
							<Grid item xs={4} mb={3}>
								<FormControl
									fullWidth
									sx={{
										"& label": {
											transformOrigin:
												selectedLanguage?.direction === "rtl"
													? "right !important"
													: "left !important",
											left: "inherit !important",
											right:
												selectedLanguage?.direction === "rtl"
													? "1.75rem !important"
													: "inherit !important",
										},
										"& legend": {
											textAlign:
												selectedLanguage?.direction == "rtl" ? "right" : "left",
										},
									}}
								>
									<InputLabel id="language-select-label">
										{getFormattedPhrase("raterManagement.addRater.placeholder.language", null, language?.cultureName)}
									</InputLabel>
									<Select
										labelId="language-select-label"
										label="Language *"
										id="language-select"
										variant="outlined"
										size="medium"
										className="text-input"
										value={language?.languageId ?? activeSurvey?.surveyDocument?.survey?.languageId}
										onChange={startChange}
										tabIndex={3}
									>
										{languages.map((item, index) => {
											return (
												<MenuItem key={index} value={item.languageId}>
													{item.translatedLanguageName ?? item.name}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					)}
				</Container>
			)}
		</Fragment>
	);
};

export default LanguageDropdown;
