import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	NavigationModes,
	ReviewQuestion,
	ScaleDisplayModes,
} from "../../@types/surveys.d";

const NavSlice = createSlice({
	name: "NavSlice",
	initialState: {
		allowNext: true,
		allowPrevious: true,
		canSubmit: false,
		currentGroupIndex: -1 as number,
		currentLayout: "ltr",
		currentQuestionIndex: -1 as number,
		currentTabIndex: -1 as number,
		currentNavigationMode: NavigationModes.BySection,
		scaleDisplayMode: ScaleDisplayModes.AlignHorizontally,
		maximumPrevious: -1 as number,
		numPrevious: 0 as number,
		continueAtNextUnanswered: false,
		shouldRenderSeqNum: false,
		showSectionSubheading: true,
		waitForSurveyUpdate: false,
		reviewUnanswered: {} as ReviewQuestion,
		tabs: [] as Array<any>,
		mainTabGroup: [] as Array<any>,
		selectedSection: "", // for use on the dashboard "My Assessments" / "Assessments for others" - unset by default
		raterPageTitle: "", // for use on Rater Management Page
		sectionId: "",
		questionNumber: 0 as number,
		goBack: false,
		checkedCount: 0 as number,
		returnUrl: "",
		defaultCount: false,
	},
	reducers: {
		setAllowNext(state, action: PayloadAction<boolean>) {
			state.allowNext = action.payload;
		},
		setAllowPrevious(state, action: PayloadAction<boolean>) {
			state.allowPrevious = action.payload;
		},
		setCanSubmit(state, action: PayloadAction<boolean>) {
			state.canSubmit = action.payload;
		},
		setCurrentGroupIndex(state, action: PayloadAction<number>) {
			state.currentGroupIndex = action.payload;
		},
		setCurrentTabIndex(state, action: PayloadAction<number>) {
			state.currentTabIndex = action.payload;
		},
		setCurrentLayout(state, action: PayloadAction<string>) {
			state.currentLayout = action.payload;
		},
		setCurrentQuestionIndex(state, action: PayloadAction<number>) {
			state.currentQuestionIndex = action.payload;
		},
		setCurrentNavigationMode(state, action: PayloadAction<NavigationModes>) {
			state.currentNavigationMode = action.payload;
		},
		setScaleDisplayMode(state, action: PayloadAction<ScaleDisplayModes>) {
			state.scaleDisplayMode = action.payload;
		},
		setMaximumPrevious(state, action: PayloadAction<number>) {
			state.maximumPrevious = action.payload;
		},
		setNumPrevious(state, action: PayloadAction<number>) {
			state.numPrevious = action.payload;
		},
		setContinueAtNextUnanswered(state, action: PayloadAction<boolean>) {
			state.continueAtNextUnanswered = action.payload;
		},
		setShouldRenderSeqNum(state, action: PayloadAction<boolean>) {
			state.shouldRenderSeqNum = action.payload;
		},
		setShowSectionSubheading(state, action: PayloadAction<boolean>) {
			state.showSectionSubheading = action.payload;
		},
		setWaitForSurveyUpdate(state, action: PayloadAction<boolean>) {
			state.waitForSurveyUpdate = action.payload;
		},
		setReviewUnanswered(state, action: PayloadAction<ReviewQuestion>) {
			state.reviewUnanswered = action.payload;
		},
		setTabs(state, action: PayloadAction<Array<any>>) {
			state.tabs = action.payload;
		},
		setMainTabGroup(state, action: PayloadAction<Array<any>>) {
			state.mainTabGroup = action.payload;
		},
		setSelectedSection(state, action: PayloadAction<string>) {
			state.selectedSection = action.payload;
		},
		setRaterPageTitle(state, action: PayloadAction<string>) {
			state.raterPageTitle = action.payload;
		},
		setSectionId(state, action: PayloadAction<string>) {
			state.sectionId = action.payload;
		},
		setQuestionNumber(state, action: PayloadAction<number>) {
			state.questionNumber = action.payload;
		},
		setGoBack(state, action: PayloadAction<boolean>) {
			state.goBack = action.payload;
		},
		setCheckedCount(state, action: PayloadAction<number>) {
			state.checkedCount = action.payload;
		},
		setReturnUrl(state, action: PayloadAction<string>) {
			state.returnUrl = action.payload;
		},
		setDefaultCount(state, action: PayloadAction<boolean>) {
			state.defaultCount = action.payload;
		},
	},
	extraReducers: (builder) => {},
});

export const {
	setAllowNext,
	setAllowPrevious,
	setCurrentGroupIndex,
	setCurrentTabIndex,
	setCurrentLayout,
	setCurrentQuestionIndex,
	setCurrentNavigationMode,
	setScaleDisplayMode,
	setMaximumPrevious,
	setNumPrevious,
	setContinueAtNextUnanswered,
	setShouldRenderSeqNum,
	setShowSectionSubheading,
	setWaitForSurveyUpdate,
	setReviewUnanswered,
	setTabs,
	setMainTabGroup,
	setCanSubmit,
	setSelectedSection,
	setRaterPageTitle,
	setSectionId,
	setQuestionNumber,
	setGoBack,
	setCheckedCount,
	setReturnUrl,
	setDefaultCount
} = NavSlice.actions;

export default NavSlice.reducer;
