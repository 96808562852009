import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Grid, Typography, Stack, IconButton } from "@mui/material";

import { RootState } from "../../../store";

import { Extension } from "../../../@types/surveys.d";

import LanguageDropdown from "../LanguageDropdown";
import {
	setMainTabGroup,
	setQuestionNumber,
	setSectionId,
	setTabs,
} from "../../../store/Navigation/NavSlice";
import { DisplayQuestionGroupComponent } from "../questions/DisplayQuestionGroupComponent";
import { isHTML } from "../../../hooks/common";

import { buildMainTabGroups } from "../store/TabbedSectionState";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import "./SurveySection.scss";
import { VideoModal } from "../../shared/videoModal";
import { fetchProgramStatuses, setLoading } from "../../../store/User/UserSlice";
import { useAuth0 } from "@auth0/auth0-react";

export type WelcomeComponentType = {
	intermediate?: boolean;
}

export const Welcome: React.FC<WelcomeComponentType> = ({ intermediate }) => {
	const dispatch = useDispatch();

	const { activeSurvey, currentSection } = useSelector(
		(state: RootState) => state.user
	);
	const [copyText, setCopyText] = useState<Extension | null>(null);

	useEffect(() => {
		dispatch(setTabs([]));

		let tabGroup = buildMainTabGroups(
			activeSurvey?.surveyDocument?.survey?.surveySections
		);
		dispatch(setMainTabGroup(tabGroup));

		if (
			currentSection?.questionGroups === null ||
			currentSection?.component === "SurveyWelcomeComponent"
		) {
			dispatch(setMainTabGroup([]));
		}

		if (activeSurvey?.surveyDocument?.survey?.surveySections) {
			let ct =
				activeSurvey?.surveyDocument?.survey?.surveySections[0].extensions?.find(
					(x) => x.name === "copyright"
				);
			if (ct) {
				setCopyText(ct as Extension);
			}
		}
		dispatch(setSectionId(""));
		dispatch(setQuestionNumber(0));
	}, [activeSurvey, currentSection]);

	return (
		<Fragment>
			{currentSection?.instructions?.length > 0 && (
				<Grid container p={0} mt={-3}>
					{currentSection.instructions.map((item, index) => {
						return (
							<Grid item xs={12} className="instructions" key={index}>
								<Typography component="div" variant="subtitle1">
									<ReactMarkdown rehypePlugins={[rehypeRaw]}>
										{item}
									</ReactMarkdown>
								</Typography>
							</Grid>
						);
					})}
					{currentSection?.questionGroups &&
						currentSection.questionGroups.map((group, index) => {
							return (
								<Grid item xs={12} className="solid-box" key={index}>
									<DisplayQuestionGroupComponent
										questionGroup={group}
										centerQuestions={false}
										hideNumber={true}
									/>
								</Grid>
							);
						})}
					{!intermediate && copyText && (
						<Grid item xs={12} mt={3} className="copyright">
							<Typography variant="body1" component={"div"}>
								<ReactMarkdown rehypePlugins={[rehypeRaw]}>
									{copyText.value}
								</ReactMarkdown>
							</Typography>
						</Grid>
					)}
				</Grid>
			)}
		</Fragment>
	);
};

export const SurveyWelcomeComponent: React.FC<WelcomeComponentType> = ({ intermediate }) => {
	const dispatch = useDispatch();
	const { user } = useAuth0();
	const { activeSurvey, currentSection, instrumentId, programStatuses, videos, programRaterStatuses } =
		useSelector((state: RootState) => state.user);
	const [imageTreatment, setImageTreatment] = useState<Extension | null>(null);
	const [videoModalOpen, setVideoModalOpen] = useState(false);
	const [is360, setIs360] = useState<boolean>(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (activeSurvey?.surveyDocument?.survey?.surveySections) {
			let it =
				activeSurvey?.surveyDocument?.survey?.surveySections[0].extensions?.find(
					(x) => x.name === "imageTreatment"
				);
			if (it) {
				setImageTreatment(it as Extension);
			}
		}
	}, [activeSurvey, currentSection]);

	useEffect(() => {
		if (!programStatuses || programStatuses.length === 0) {
			if (user) {
				let acctId = user["http://schemas.ccl.org/accounts/claims/account/id"];
				if (programStatuses.length === 0 && !programRaterStatuses?.surveyStatuses?.length) {
					dispatch(setLoading(false));
				}
				//rater surveys are in programRaterStatuses, check before
				//retrying fetching surveys, for purely 'rater users'
				else if(programRaterStatuses?.surveyStatuses?.length > 0) {
					dispatch(setLoading(false));
				}
				else {
					dispatch(fetchProgramStatuses(acctId));
				}
			}
		} else {
			let currentInstrumentStatus = programStatuses[0].instrumentStatuses
				.slice()
				.filter((x) => x.instrumentId === instrumentId);
			setIs360(
				currentInstrumentStatus?.length > 0
					? currentInstrumentStatus[0].is360
					: false
			);
		}
	}, [user, instrumentId, programStatuses, programRaterStatuses]);

	const generateMediaList = (thumbnails: boolean) => {
		if (thumbnails) {
			//taking this as a participant: 0, or a rater: 1
			if (activeSurvey.surveyDocument.survey.surveyTypeId === 1) {
				return [videos.rater.thumb];
			} else {
				if (is360) {
					return [videos.self360.thumb];
				}
				return [videos.self.thumb];
			}
		} else {
			//taking this as a participant: 0, or a rater: 1
			if (activeSurvey.surveyDocument.survey.surveyTypeId === 1) {
				return [videos.rater.video];
			} else {
				if (is360) {
					return [videos.self360.video];
				}
				return [videos.self.video];
			}
		}
	};

	return (
		<Fragment>
			<VideoModal
				isOpen={videoModalOpen}
				onClose={() => setVideoModalOpen(false)}
				videoList={generateMediaList(false)}
				thumbnailList={generateMediaList(true)}
			/>
			<Grid>
				{activeSurvey?.surveyDocument?.languages &&
				activeSurvey?.surveyDocument?.languages.length > 1 && 
				!intermediate ? (
					<Grid item xs={12} sm={12} md={12} className="rounded-card">
						<LanguageDropdown onChange={(e) => console.log("onChange: ", e)} />
					</Grid>
				) : (
					<></>
				)}
				<Grid item mt={5} mb={7} className="rounded-card">
					<Stack direction={"row"} alignItems={"center"}>
						<Typography
							variant="subtitle2"
							sx={{ flex: 1 }}
							fontWeight={700}
							mb={1}
						>
							{activeSurvey.surveyDocument?.survey?.name}
							{intermediate ?
								<></>
								:
								<IconButton
									onClick={() => setVideoModalOpen(true)}
									sx={{ pl: 5, color: "#307E92" }}
								>
									<VideoCameraBackIcon />
								</IconButton>
							}
						</Typography>

						{imageTreatment && (
							<Grid item xs={12}>
								{isHTML(imageTreatment.value) ? (
									<ReactMarkdown rehypePlugins={[rehypeRaw]}>
										{imageTreatment.value}
									</ReactMarkdown>
								) : (
									<img
										src={imageTreatment.value}
										height="75px"
										alt="Survey Logo"
									/>
								)}
							</Grid>
						)}
					</Stack>
					<Welcome intermediate={intermediate}/>
				</Grid>
			</Grid>
		</Fragment>
	);
};
