import { Dispatch } from "@reduxjs/toolkit";
import {
	SurveyAnswer,
	SurveySection,
	SurveyDocumentStateModel,
	SurveyResponse,
	NavigationModes,
	ReviewQuestion,
	UnAnsweredQuestion,
} from "../../../@types/surveys.d";
import {
	setAllowPrevious,
	setCurrentGroupIndex,
	setCurrentQuestionIndex,
	setCurrentTabIndex,
	setNumPrevious,
} from "../../../store/Navigation/NavSlice";
import {
	setCurrentSection,
	setQuestionList,
} from "../../../store/User/UserSlice";
import { getIndex } from "../../../services/apiService";
import {
	buildSurveySection,
	getSection,
	getSurveyReviewCards,
} from "./SurveyState";

export const findNextUnansweredQuestion = (state: SurveyDocumentStateModel) => {
	let unAnswered = {} as UnAnsweredQuestion;

	// get the unanswered questions for review
	let surveySectionId = buildSurveySection(state);
	if (
		surveySectionId === 0 &&
		(state.surveyDocument.survey.surveySections[surveySectionId]
			.questionGroups === null &&
			state.surveyDocument.survey.surveySections[surveySectionId].component ===
				"SurveyWelcomeComponent")
	) {
		surveySectionId = surveySectionId + 1;
	}

	let reviewCard = getSurveyReviewCards(state);
	let noAnswered: number = -1;
	let requiredNoOfAnswers: number = 0;
	let competenciesSection = state.surveySections.findIndex(
		(a) => a.component === "SurveyCompetenciesQuestionsComponent"
	);

	let qNo = reviewCard.map((x) => {
		if (
			x.sectionId ===
			state.surveyDocument.survey.surveySections[surveySectionId].sectionId
		) {
			if (x.component === "SurveyCompetenciesQuestionsComponent") {
				// validate if the required no of questions is answered
				noAnswered = x.numberAnswered;

				let validationRule: any =
					state.surveyDocument.survey.surveySections[competenciesSection]
						.validationRule;
				if (validationRule?.conditions?.all) {
					requiredNoOfAnswers = validationRule?.conditions?.all[0].value;
				}
			}
			return x.unansweredQuestions.filter((a) => Number(a?.number ?? a?.formItemId));
		}
	});

	// if no unanswered questions in the competencies section, go to the next section
	if (noAnswered === requiredNoOfAnswers) {
		unAnswered.questionIndex = 1;
		unAnswered.section =
			state.surveyDocument.survey.surveySections[
				competenciesSection + 1
			].sectionId;
	} else {
		let firstUnansweredQuestion = Number(
			qNo.filter((x) => x !== undefined).map((a) => a?.shift()?.formItemId)
		);

		if (firstUnansweredQuestion > 0) {
			unAnswered.questionIndex = firstUnansweredQuestion;
			unAnswered.section =
				state.surveyDocument.survey.surveySections[surveySectionId].sectionId;
		}
	}

	if (unAnswered) {
		// Using the next unanwered question, get the next group and question.
		let sIndex = state.surveySections?.findIndex(
			(a) => a.sectionId === unAnswered.section
		);
		if (sIndex > 0) {
			let nextSection = state.surveySections[sIndex];
			nextSection?.questionGroups?.every((group, index) => {
				let q = group.questions.findIndex(
					(a) => a.formItemId === unAnswered.questionIndex
				);
				if (q >= 0) {
					unAnswered.groupIndex = index;
					unAnswered.questionIndex = q;
					return false;
				}
				return true;
			});
		}
	}
	return unAnswered;
};

export const gotoPreviousQuestion = (
	currentQuestionIndex: number,
	currentGroupIndex: number,
	currentSection: SurveySection,
	activeSurvey: SurveyResponse,
	dispatch: Dispatch
) => {
	if (currentGroupIndex > -1) {
		let currentGroup = currentSection?.questionGroups[currentGroupIndex];
		if (
			currentQuestionIndex > 0 &&
			currentQuestionIndex < currentGroup?.questions?.length
		) {
			let prev = currentQuestionIndex - 1;
			dispatch(setCurrentQuestionIndex(prev));
		} else {
			let newGroup = gotoPreviousQuestionGroup(
				currentGroupIndex,
				currentSection,
				activeSurvey,
				dispatch
			);

			if (newGroup?.questions?.length) {
				dispatch(setCurrentQuestionIndex(newGroup.questions.length - 1));
			} else {
				dispatch(setCurrentQuestionIndex(-1));
			}
		}
	} else {
		gotoPreviousQuestionGroup(
			currentGroupIndex,
			currentSection,
			activeSurvey,
			dispatch
		);
	}
};

export const gotoPreviousQuestionGroup = (
	currentGroupIndex: number,
	currentSection: SurveySection,
	activeSurvey: SurveyResponse,
	dispatch: Dispatch
) => {
	if (
		currentGroupIndex > 0 &&
		currentGroupIndex < currentSection?.questionGroups?.length
	) {
		let prev = currentGroupIndex - 1;
		dispatch(setCurrentGroupIndex(prev));

		return currentSection?.questionGroups[prev];
	} else {
		let newSection = gotoPreviousSection(
			currentSection,
			activeSurvey,
			dispatch
		);

		if (newSection.questionGroups?.length) {
			let newGroupIndex = newSection?.questionGroups?.length - 1;
			dispatch(setCurrentGroupIndex(newGroupIndex));

			if (newGroupIndex < newSection?.questionGroups?.length) {
				dispatch(
					setCurrentQuestionIndex(
						newSection?.questionGroups[newGroupIndex].questions?.length - 1
					)
				);
			}

			return newSection?.questionGroups[newGroupIndex];
		} else {
			dispatch(setCurrentQuestionIndex(-1));
			dispatch(setCurrentGroupIndex(-1));
			dispatch(setCurrentTabIndex(-1));
		}
	}
};

export const gotoPreviousSection = (
	currentSection: SurveySection,
	activeSurvey: SurveyResponse,
	dispatch: Dispatch
) => {
	let sectionIndex = getIndex(
		currentSection,
		activeSurvey.surveyDocument.survey.surveySections,
		"sectionId"
	);
	let newSection = getSection(sectionIndex - 1, activeSurvey);
	dispatch(setCurrentSection(newSection));
	dispatch(setCurrentQuestionIndex(0));
	dispatch(setCurrentTabIndex(0));

	return newSection;
};

export const gotoPrevious = (
	numPrevious: number,
	maximumPrevious: number,
	reviewUnanswered: ReviewQuestion,
	currentNavigationMode: NavigationModes,
	currentQuestionIndex: number,
	currentGroupIndex: number,
	currentSection: SurveySection,
	activeSurvey: SurveyResponse,
	dispatch: Dispatch
) => {
	switch (currentNavigationMode) {
		case NavigationModes.ByQuestion:
			gotoPreviousQuestion(
				currentQuestionIndex,
				currentGroupIndex,
				currentSection,
				activeSurvey,
				dispatch
			);
			break;

		case NavigationModes.ByQuestionGroup:
			gotoPreviousQuestionGroup(
				currentGroupIndex,
				currentSection,
				activeSurvey,
				dispatch
			);
			break;

		case NavigationModes.BySection:
		default:
			gotoPreviousSection(currentSection, activeSurvey, dispatch);
			break;
	}

	let newPrev = numPrevious + 1;
	dispatch(setNumPrevious(newPrev));
	if (newPrev >= maximumPrevious) dispatch(setAllowPrevious(false));
	if (
		reviewUnanswered?.sectionId === "" &&
		reviewUnanswered?.formItemId === 0
	) {
		window.scrollTo(0, 0);
	}
};

export const gotoNextUnansweredQuestion = (
	activeSurvey: SurveyResponse,
	activeAnswers: Array<SurveyAnswer>,
	dispatch: Dispatch
) => {
	let sectionNdx = -1;

	let scanSections = true;
	while (scanSections) {
		++sectionNdx;
		let groupNdx = -1;
		if (
			sectionNdx >= activeSurvey?.surveyDocument?.survey?.surveySections.length
		) {
			scanSections = false;
		} else {
			let section =
				activeSurvey?.surveyDocument?.survey?.surveySections[sectionNdx];

			let scanGroups = true;
			while (scanGroups) {
				++groupNdx;
				let quesNdx = -1;
				if (
					!section.questionGroups ||
					groupNdx >= section?.questionGroups?.length
				) {
					scanGroups = false;
				} else {
					let group = section.questionGroups[groupNdx];

					let scanQuestions = true;
					while (scanQuestions) {
						++quesNdx;
						if (!group?.questions || quesNdx >= group?.questions?.length) {
							scanQuestions = false;
						} else {
							let question = group.questions[quesNdx];
							let answer: string | null = null;
							activeAnswers.map((ans) => {
								if (ans.formItemId === question.formItemId) {
									answer = ans.answer;
								}
							});
							if (!answer) {
								// Found it, set and return to exit all loops.
								dispatch(setCurrentGroupIndex(groupNdx));
								dispatch(setCurrentQuestionIndex(quesNdx));
								dispatch(setCurrentSection(section));
								return;
							}
						}
					}
				}
			}
		}
	}
	let lastSectionNdx =
		activeSurvey?.surveyDocument?.survey?.surveySections?.length;
	let lastSection =
		activeSurvey?.surveyDocument?.survey?.surveySections[lastSectionNdx - 2];
	dispatch(setCurrentGroupIndex(-1));
	dispatch(setCurrentQuestionIndex(-1));
	dispatch(setCurrentSection(lastSection));
};

export const getNextUnansweredSection = (
	activeSurvey: SurveyResponse,
	state: SurveyDocumentStateModel
) => {
	// get the next unanswered question
	let res = findNextUnansweredQuestion(state);

	if (Object.keys(res).length !== 0) {
		let sectionIndex =
			activeSurvey?.surveyDocument?.survey?.surveySections?.findIndex(
				(a) => a.sectionId === res.section
			);
		if (sectionIndex < 0) {
			return activeSurvey?.surveyDocument?.survey?.surveySections[0];
		}
		return activeSurvey?.surveyDocument?.survey?.surveySections[sectionIndex];
	}

	if (activeSurvey?.surveyDocument?.survey?.instrumentId === 2947) {
		// For WPB5 do nothing
		return activeSurvey?.surveyDocument?.survey?.surveySections[0];
	}

	// if user has answered all the questions, take them to the review screen
	let sectionIndex =
		activeSurvey?.surveyDocument?.survey?.surveySections?.findIndex(
			(a) => a.component === "SurveyReviewQuestionsComponent"
		);

	let reviewSection =
		activeSurvey?.surveyDocument?.survey?.surveySections[sectionIndex];
	return reviewSection;
};

export const gotoNextQuestion = (
	currentQuestionIndex: number,
	currentGroupIndex: number,
	currentSection: SurveySection,
	activeSurvey: SurveyResponse,
	dispatch: Dispatch
) => {
	if (currentQuestionIndex > -1) {
		let next = currentQuestionIndex;
		let currentGroup = currentSection?.questionGroups[currentGroupIndex];
		if (currentQuestionIndex < currentGroup?.questions.length - 1) {
			next += 1;
		} else {
			next = -1;
			gotoNextQuestionGroup(
				currentGroupIndex,
				currentSection,
				activeSurvey,
				dispatch
			);
		}
		dispatch(setCurrentQuestionIndex(next));
	} else {
		gotoNextQuestionGroup(
			currentGroupIndex,
			currentSection,
			activeSurvey,
			dispatch
		);
	}
};

export const gotoNextQuestionGroup = (
	currentGroupIndex: number,
	currentSection: SurveySection,
	activeSurvey: SurveyResponse,
	dispatch: Dispatch
) => {
	if (currentGroupIndex > -1) {
		let next = currentGroupIndex;
		if (currentGroupIndex < currentSection?.questionGroups?.length - 1) {
			next += 1;
		} else {
			next = -1;
			gotoNextSection(currentSection, activeSurvey, dispatch);
		}
		dispatch(setCurrentGroupIndex(next));
	} else {
		dispatch(setCurrentGroupIndex(-1));
		gotoNextSection(currentSection, activeSurvey, dispatch);
	}
};

export const gotoNextSection = (
	currentSection: SurveySection,
	activeSurvey: SurveyResponse,
	dispatch: Dispatch
) => {
	let sectionIndex = getIndex(
		currentSection,
		activeSurvey.surveyDocument.survey.surveySections,
		"sectionId"
	);
	if (sectionIndex < 0) {
		return;
	}

	let newSection = getSection(sectionIndex + 1, activeSurvey);
	dispatch(setCurrentSection(newSection));
	dispatch(setCurrentQuestionIndex(0));

	return newSection;
};

export const gotoNext = (
	currentNavigationMode: NavigationModes,
	continueAtNextUnanswered: boolean,
	currentQuestionIndex: number,
	currentGroupIndex: number,
	currentSection: SurveySection,
	activeSurvey: SurveyResponse,
	activeAnswers: Array<SurveyAnswer>,
	numPrevious: number,
	maximumPrevious: number,
	dispatch: Dispatch
) => {
	switch (currentNavigationMode) {
		case NavigationModes.ByQuestion:
			if (continueAtNextUnanswered) {
				gotoNextUnansweredQuestion(activeSurvey, activeAnswers, dispatch);
			} else {
				gotoNextQuestion(
					currentQuestionIndex,
					currentGroupIndex,
					currentSection,
					activeSurvey,
					dispatch
				);
			}
			break;

		case NavigationModes.ByQuestionGroup:
			gotoNextQuestionGroup(
				currentGroupIndex,
				currentSection,
				activeSurvey,
				dispatch
			);
			break;

		case NavigationModes.BySection:
		default:
			gotoNextSection(currentSection, activeSurvey, dispatch);
			break;
	}
	let newPrev = numPrevious - 1;
	if (newPrev >= 0) {
		dispatch(setNumPrevious(newPrev));
	}
	if (newPrev < maximumPrevious) {
		dispatch(setAllowPrevious(true));
	}
	window.scrollTo(0, 0);
};
