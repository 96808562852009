import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";

const createTheme = (name: string) => {
	let themeConfig = variants.find((variant) => variant.name === name);

	if (!themeConfig) {
		console.warn(new Error(`The theme ${name} is not valid`));
		themeConfig = variants[0];
	}

	return createMuiTheme(
		{
			spacing: 4,
			breakpoints: breakpoints,
			// @ts-ignore
			components: components,
			typography: typography,
			shadows: shadows,
			palette: themeConfig.palette,
		},
		{
			name: themeConfig.name,
			header: themeConfig.header,
			footer: themeConfig.footer,
			sidebar: themeConfig.sidebar,
		}
	);
};

// There may be a better way to do this as part of the Mui theme process.
export const themeExtensions: any = {
	light: {
		toolbar: {
			primary: "#B7DDE2",
			secondary: "#CCE7EB",
		},
		rootBox: "#F1F1F2",
	},
	dark: {
		toolbar: {
			primary: "#353841",
			secondary: "#3F424B",
		},
		rootBox: "#1F222C",
	},
};

export default createTheme;
