import { Card, CardContent, Tooltip } from "@mui/material"
import components from "../../theme/components";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { VideoModal } from "../shared/videoModal";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const DisplayAdvertisement: React.FC = () => {
    const { videos } = useSelector((state: RootState) => state.user);
    const [videoActive, setVideoActive] = useState<boolean>(false);
    
    return (
        <Card sx={components.advertisementCard}>
            <VideoModal 
                isOpen={videoActive} 
                onClose={() => setVideoActive(false)} 
                videoList={[
                    videos.welcome.video
                ]}
                thumbnailList={[
                    videos.welcome.thumb2
                ]}
            />
            <Link to="" onClick={() => setVideoActive(true)}>
                {/* <Tooltip title={"Click here to play the walkthrough video"}> */}
                    <CardContent sx={{p: 0, '&:last-child': { pb: 0 }, zIndex: 1, ":hover .thumb": {opacity: 0.5}, ":hover .label": {opacity: 1}}}>
                        <img
                            className="thumb"
                            src={videos.welcome.thumb1}
                            style={{ width: "100%", marginBottom: -5, zIndex: 0 }}
                        />
                    </CardContent>
                {/* </Tooltip> */}
            </Link>
        </Card>
    )
}