import axios from "axios";
import { AddRaterRequest, EmailRequest } from "../@types/raters.d";
import { Profile } from "../@types/surveys.d";
import AppEmitter from "./appEmitter";

const envSettings = window as any;

const maxRetries = 3;
let retryCount = 0;

const sleep = (milliseconds: number) => {
	return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

axios.interceptors.response.use(
	async (response) => {
		retryCount = 0;
		return response;
	},
	async (error) => {
		if (error.toJSON) {
			let errorJson = error.toJSON();

			if (errorJson.message.indexOf("401") > 0) {
				if (retryCount > maxRetries) {
					// Notify of permanent failure.
					console.log("emit APPLICATION_AXIOS_AUTH_ERROR_DONE");
					AppEmitter.emit("APPLICATION_AXIOS_AUTH_ERROR_DONE", {
						retryCount,
						error,
					});

					return new Promise((reject) => {
						reject(error);
					});
				} else {
					++retryCount;
					// Notify then retry.
					console.log("emit APPLICATION_AXIOS_AUTH_ERROR");
					AppEmitter.emit("APPLICATION_AXIOS_AUTH_ERROR", {
						retryCount,
						error,
					});

					// Delay
					await sleep(retryCount * 1000);

					// Retry
					const config = error.config;
					let idToken = localStorage.getItem("token") || "";
					config.headers = {
						Authorization: `Bearer ${idToken}`,
					};
					return new Promise((resolve) => {
						resolve(axios(config));
					});
				}
			} else {
				AppEmitter.emit("APPLICATION_AXIOS_ERROR", errorJson);
			}
		} else {
			AppEmitter.emit("APPLICATION_AXIOS_ERROR", { error: error });
		}
		throw error;
	}
);

export const getUserProfile = async (
	individualId: number,
	languageId: number,
	token?: string
) => {
	try {
		let idToken = token || localStorage.getItem("token");

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/api/profile/my-profile/${individualId}/${languageId}`;

		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});

		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const saveUserProfile = async (profile: Profile) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/api/profile/my-profile`;

		let postObj = profile;

		let response = await axios.put(url, postObj, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});

		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAllSurveyStatus = async (acctId: string) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/surveys/${acctId}`;

		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAllRatersStatus = async (
	acctId: string,
	preferredLanguageId: number
) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/raters/status/all/${acctId}/${preferredLanguageId}`;

		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAllRaters = async (
	participantId: string,
	instrumentId: string,
	preferredLanguageId: string
) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/raters/management/${participantId}/instrument/${instrumentId}/language/${preferredLanguageId}`;

		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const resendRaterInvitation = async (emailRequest: EmailRequest) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/raters/management/resend`;

		let response = await axios.post(
			url,
			{
				emailRequest: emailRequest,
			},
			{
				headers: {
					Authorization: `Bearer ${idToken}`,
				},
			}
		);
		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const addRatersRequest = async (
	addReaterRequests: Array<AddRaterRequest>
) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/raters/management/add-raters`;

		let payload = {
			addRaterRequests: addReaterRequests,
		};

		let response = await axios.post(url, payload, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteRater = async (raterId: number) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/raters/management/${raterId}`;

		let response = await axios.delete(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const remindRaters = async (emailRequests: Array<EmailRequest>) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/raters/management/reminders`;

		let payload = {
			emailRequests: emailRequests,
		};

		let response = await axios.post(url, payload, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const searchRatersByEmail = async (emailAddress: string) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/raters/management/search`;

		let searchRequest = {
			email: emailAddress,
		};

		let response = await axios.post(url, searchRequest, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getActiveLanguages = async (
	instrumentId: number,
	languageId: number
) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/raters/management/languages/${instrumentId}/${languageId}`;

		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getSurveyByLanguage = async (
	acctId: string,
	instrumentId: number,
	languageId: number,
	surveyTypeId: number
) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/surveys/${acctId}/${instrumentId}?languageId=${languageId}&surveyTypeId=${surveyTypeId}`;

		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getSurveyByParticipantAndLanguage = async (
	acctId: string,
	instrumentId: number,
	participantId: number,
	languageId: number,
	surveyTypeId: number
) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = "";
		if (languageId > 0) {
			url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/surveys/${acctId}/${instrumentId}?participantId=${participantId}&languageId=${languageId}&surveyTypeId=${surveyTypeId}`;
		}
		else {
			url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/surveys/${acctId}/${instrumentId}?participantId=${participantId}&surveyTypeId=${surveyTypeId}`;
		}

		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const completeSurvey = async (
	surveyId: string,
	instrumentId: number
) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/surveys/${surveyId}/${instrumentId}`;

		let response = await axios.put(url, null, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const saveItemResponse = async (
	surveyId: string,
	instrumentId: number,
	sequenceNum: number,
	itemId: number,
	itemResponse: string
) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/surveys/${surveyId}/${instrumentId}/${sequenceNum}/${itemId}`;
		let postObj = {
			response: `${itemResponse}`,
		};
		let response = await axios.put(url, postObj, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const changeSurveyLanguage = async (
	accountId: string,
	instrumentId: number,
	participantId: number,
	currentLanguageId: number,
	newLanguageId: number,
	surveyTypeId: number
) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/surveys/change-language/${accountId}/${instrumentId}/${currentLanguageId}/${newLanguageId}?participantId=${participantId}&surveyTypeId=${surveyTypeId}`;
		let response = await axios.put(url, null, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const clearItemResponse = async (
	surveyId: string,
	instrumentId: number,
	itemId: number,
	sequenceNum: number
) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/surveys/${surveyId}/${instrumentId}/${itemId}/${sequenceNum}`;

		let response = await axios.delete(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});
		if (response.status === 200) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getIndex = (value, arr, prop) => {
	let index = -1;
	for (var i = 0; i < arr.length; i++) {
		if (arr[i][prop] === value[prop]) {
			index = i;
		}
	}
	return index; //to handle the case where the value doesn't exist
};

export const getDocument = async (
	participantId: number,
	documentId: number,
	individualId: number
) => {
	try {
		let idToken = localStorage.getItem("token") || "";
		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/participant-landing/documents/${participantId}/${documentId}/${individualId}`;

		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
				Accept: "application/pdf",
			},
			responseType: "blob",
		});

		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAsset = async (
	individualId: number,
	assetInfoId: number,
	version: string
) => {
	try {
		let idToken = localStorage.getItem("token") || "";
		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/participant-landing//${individualId}/assets/${assetInfoId}/${version}`;

		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
				Accept: "application/pdf",
			},
			responseType: "blob",
		});

		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getApplicationLanguages = async (
	languageId: number
) => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/api/profile/application-languages/${languageId}`;

		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});

		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getSurveyUsage = async () => {
	try {
		let idToken = localStorage.getItem("token") || "";

		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/surveys/survey-usage/`;
		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});

		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVideoURL = async (
	language: string,
	videoId: string
) => {
	try {
		let idToken = localStorage.getItem("token") || "";
		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/videos/getVideo/${language}/${videoId}`;
		
		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});

		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getApplicationResponse = async (
	preferredLanguageId: number,
) => {
	try {
		let idToken = localStorage.getItem("token") || "";
		let url = `${envSettings.REACT_APP_CCL_API_MANAGEMENT_URL}/assessments2-api/api/application/application-response/${preferredLanguageId}`;

		let response = await axios.get(url, {
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
		});

		if (response.status === 200 || response.status === 204) {
			return Promise.resolve((await response.data) as any);
		} else {
			return Promise.reject(response.statusText);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};