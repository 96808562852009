import { useSelector } from "react-redux";
import { Fragment, useEffect } from "react";
import { Box, Card, Grid, Stack, Typography, useTheme } from "@mui/material";

import { Loading } from "../components";

import { RootState } from "../store";

import { Layout } from "../components/chrome/Layout";

import { AssessmentsForOthers } from "./home/AssessmentsForOthers";
import { AssessmentsForMe } from "./home/AssessmentsForMe";

import "./HomePage.scss";
import SkeletonLoader from "../components/shared/skeletonLoader";
import InfoIcon from '@mui/icons-material/Info';

export const HomePage: React.FC = () => {
	const { loading } = useSelector((state: RootState) => state.user);

	const { returnUrl, selectedSection } = useSelector(
		(state: RootState) => state.navigation
	);

	const { applicationMessage } = useSelector(
		(state: RootState) => state.user
	);
	
	const theme = useTheme();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		console.log(applicationMessage);
	}, [applicationMessage]);

	useEffect(() => {
		if (returnUrl !== "") {
			localStorage.setItem("redirectUser","");
			window.location.href = returnUrl;
		}
		else {
			localStorage.setItem("redirectUser","");
		}
	}, [returnUrl]);

	return (
		<Layout>
			<Box>
				{loading && 
				<Grid container columnSpacing={8} rowSpacing={5}>
					<Grid item xs={4} rowSpacing={1} mb={5}>
						<SkeletonLoader cardType="aboutMe" />
					</Grid>
					<Grid item xs={8} rowSpacing={1} mb={5}>
						<SkeletonLoader cardType="survey" />
						<SkeletonLoader cardType="survey" />
					</Grid>
				</Grid>}
				{returnUrl !== "" ? (
					<Fragment />
				) : (
					<Fragment>
						{applicationMessage.haveMessage ? <Card
							sx={{
								mt: 3,
								borderColor: theme.palette.primary.main,
								borderWidth: 1,
								borderStyle: "solid",
							}}
						>
							<Stack sx={{ flexDirection: "row", p: 3, pt: 2 }}>
								<InfoIcon
									sx={{ mr: 2, color: theme.palette.primary.main }}
								/>
								<Typography>
									{applicationMessage.message}
								</Typography>
							</Stack>
						</Card> : <></>}
						{selectedSection === "Own" && <AssessmentsForMe />}
						{selectedSection === "Others" && <AssessmentsForOthers />}
					</Fragment>
				)}
			</Box>
		</Layout>
	);
};
